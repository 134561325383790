import React, {createContext, useState, useContext, useReducer, useEffect} from "react";

import firebase from "@/firebase";

const initialState = {uid: null};

export const UserContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "set":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const UserContextProvider = ({children}) => {
  const [user, userDispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  const setUser = (keyOrObj, val) => {
    if (keyOrObj && val) {
      userDispatch({type: "set", payload: {[keyOrObj]: val}});
    } else if (keyOrObj && typeof keyOrObj === "object") {
      userDispatch({type: "set", payload: keyOrObj});
    } else {
      throw new Error("Invalid parameters for setUser. Please pass either an object or key/value pair.");
    }
  };

  const setUserData = async (authUser, {trackingOptIn = false} = {}) => {
    let userData = {
      uid: authUser.uid,
      isAdmin: false,
    };

    setUser(userData);
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async authUser => {
      if (authUser) {
        setUserData(authUser);
      } else {
        userDispatch({type: "reset"});
        //firebase.auth().signInAnonymously();
      }

      setLoading(false);
    });
  }, []);

  const value = {
    user,
    userDispatch,
    setUser,
    setUserData,
    loadingUser: loading,
    authenticated: user.uid !== null && !user.isAnonymous,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

export default {
  useUser,
  UserContext,
  UserContextProvider,
};
