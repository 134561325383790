const firebaseConfig = {
  apiKey: "AIzaSyAqEbs1_bW_5ggvT0mK0hq2rsL_-kD2AoA",
  authDomain: "meal-hacks.firebaseapp.com",
  databaseURL: "https://meal-hacks.firebaseio.com",
  projectId: "meal-hacks",
  storageBucket: "meal-hacks.appspot.com",
  messagingSenderId: "602333461148",
  appId: "1:602333461148:web:008810c667d1ef21fa4b59",
  measurementId: "G-ZZ1FNJ2V96"
};

export default firebaseConfig;
