import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

import config from "./config";

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;
